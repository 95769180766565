// require("html-loader!../index.html");
// require("html-loader!../site.html");
import 'normalize.css/normalize.css';
import './styles/index.scss';
import 'bootstrap/dist/css/bootstrap.css';

import 'jquery.easing';
import 'popper.js';
import 'bootstrap';

document.addEventListener("DOMContentLoaded", () => {

    (function($) {
        "use strict"; // Start of use strict

        let allowsubmit = false;
        function dataCallback(response) {
            //recaptcha
            axios(
                {
                    method: 'get',
                    url: "/checkRecaptcha?response=" + encodeURIComponent(response)
                }
            ).then(
                function(response) {
                    if( response.status === 200 ){
                        allowsubmit = true;
                    }
                }
            );
        }
        function dataExpiredCallback() {
        }

        var config = {
            apiKey: "AIzaSyD6WmUXZfc2ZSlDhHnqqSMDyJnrCJqUqyQ",
            authDomain: "hoorcentrumjoliendesmet-8d901.firebaseapp.com",
            databaseURL: "https://hoorcentrumjoliendesmet-8d901.firebaseio.com",
            projectId: "hoorcentrumjoliendesmet-8d901",
            storageBucket: "hoorcentrumjoliendesmet-8d901.appspot.com",
            messagingSenderId: "702547761548"
        };
        firebase.initializeApp(config);

        $("#contactform").on('submit', function (e) {
            // create json from form
            e.preventDefault();


            $('#forminvald').hide();
            $('#formsuccess').hide();

            var data = $('#contactform').serializeArray().reduce(function(obj, item) {
                obj[item.name] = item.value;
                return obj;
            }, {});

            axios({
                method: 'post',
                url: '/checkRecaptcha',
                data: data
            }).then(
                function() {
                    $('#formsuccess').show();
                    document.getElementById("contactform").reset();
                },
                function() {
                    $('#forminvald').show();
                }
            );
        });

        $("#submitBtn").on('click', function (e) {
                e.preventDefault();
                $('#contactform').submit();
                //$('#afspraakModal').modal('hide');
        })

        $('[data-spy="scroll"]').on('activate.bs.scrollspy', function () {
            console.log( arguments );
        })

        // Smooth scrolling using jQuery easing
        $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: (target.offset().top - 54)
                    }, 1000, "easeInOutExpo");
                    return false;
                }
            }
        });

        // Closes responsive menu when a scroll trigger link is clicked
        $('.js-scroll-trigger').click(function() {
            $('.navbar-collapse').collapse('hide');
        });

        // Activate scrollspy to add active class to navbar items on scroll
        $('body').scrollspy({
            target: '#mainNav',
            offset: 56
        });

        // Collapse Navbar
        var navbarCollapse = function() {
            if ($("#mainNav").offset().top > 100) {
                $("#mainNav").addClass("navbar-shrink");
            } else {
                $("#mainNav").removeClass("navbar-shrink");
            }
        };
        // Collapse now if page is not at top
        navbarCollapse();
        // Collapse the navbar when page is scrolled
        $(window).scroll(navbarCollapse);

        // Hide navbar when modals trigger
        $('.portfolio-modal').on('show.bs.modal', function(e) {
            $('.navbar').addClass('d-none');
        })
        $('.portfolio-modal').on('hidden.bs.modal', function(e) {
            $('.navbar').removeClass('d-none');
        });

    })(jQuery); // End of use strict

});

